<template>
	<v-card class="wr_grey_1" flat>
		<AppFeaturesTheme
			:features="features"
			@handle-function-call="handleFunctionCall"
		></AppFeaturesTheme>

		<MindmapNameDialog
			v-model="dialog"
			:dialog="dialog"
			@close="dialog = false"
			@name-created="create"
		></MindmapNameDialog>

		<!-- Dialog to ask id of another map to access-->
		<CollaborateBoardDialog
			v-model="collaborate_dialog"
			:dialog="collaborate_dialog"
			action_label="app.access_map"
			@close="collaborate_dialog = false"
			@confirm="redirectToMap"
		></CollaborateBoardDialog>
	</v-card>
</template>

<script>
import { toolsWizardMapMixin } from "@/mixins/tools/wizardMindmap/commonInfoMixin.js";

export default {
	name: "ToolsWizardMindmap",

	mixins: [toolsWizardMapMixin],

	data() {
		return {
			features: [
				{
					img: "plus",
					label: "app.new_map",
					method_name: "openNewMapDialog",
				},
				{
					img: "step",
					label: "app.my_maps",
					method_name: "redirectToList",
				},
				{
					img: "partners",
					label: "app.collaborate_maps",
					method_name: "openCollaborationDialog",
				},
			],
		};
	},
};
</script>
